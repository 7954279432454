import React, { useState, useEffect } from 'react';

const styles = `
  :root {
    --primary-900: #323563;
    --primary-1000: #242648;
    --primary-800: #40437f;
    --secondary-color: #FAFAFA;
  }

  .container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    position: relative;
    overflow: hidden;
    background: radial-gradient(
      circle at center,
      var(--primary-800) 0%,
      var(--primary-900) 45%,
      var(--primary-1000) 100%
    );
  }

  .content {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: transparent;
  }

  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin-top: -5%;
  }

  .logo {
    width: 100px;
    height: 100px;
  }

  .title {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--secondary-color);
    margin: 2rem 0;
    text-align: center;
  }

  .text-container {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    font-size: 1.8rem;
    color: var(--secondary-color);
    transition: opacity 0.5s ease-in-out;
  }

  .footer {
    background-color: rgba(36, 38, 72, 0.3);
    backdrop-filter: blur(5px);
    border-top: 1px solid rgba(250, 250, 250, 0.1);
    padding: 1.5rem;
    margin-top: auto;
    position: relative;
    z-index: 2;
  }

  .footer-links {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  .link {
    color: var(--secondary-color);
    text-decoration: none;
    transition: opacity 0.2s ease;
    font-size: 0.9rem;
    opacity: 0.7;
  }

  .link:hover {
    opacity: 1;
  }
`;

const LandingPage = () => {
  const texts = [
    { text: "Soon", id: 1 },
    { text: "Скоро", id: 2 },
    { text: "Tez kunda", id: 3 }
  ];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentTextIndex((prev) => (prev + 1) % texts.length);
        setIsTransitioning(false);
      }, 500);
    }, 3000);

    return () => {
      clearInterval(interval);
      document.head.removeChild(styleSheet);
    };
  }, [texts.length]);

  return (
    <div className="container">
      <div className="content">
        <main className="main">
          <div className="logo-container">
            <img 
              src="white_logo192.png"
              alt="WorkXplorer Logo" 
              className="logo" 
            />
          </div>
          
          <h1 className="title">WorkXplorer</h1>
          
          <div className="text-container">
            <span
              className="text"
              style={{ opacity: isTransitioning ? 0 : 1 }}
            >
              {texts[currentTextIndex].text}
            </span>
          </div>
        </main>

        <footer className="footer">
          <div className="footer-links">
            <a href="/terms" className="link">Terms of Service</a>
            <a href="/privacy" className="link">Privacy Policy</a>
            <a href="/offer" className="link">Public Offer</a>
            <a href="/contact" className="link">Contact Us</a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;