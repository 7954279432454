import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/UniversityQuizAppStyles/InstructionsModal.module.css';

const InstructionsModal = ({ onClose, onStart }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button
          className={styles.closeButton}
          onClick={onClose}
          aria-label={t('instructions.close')}
        >
          ✕
        </button>
        <h2 className={styles.modalTitle}>
          {t('instructions.title')}
        </h2>
        
        <h3 className={styles.modalSubtitle}>
          {t('instructions.subtitle')}
        </h3>
        <div className={styles.instructionsList}>
          <p className={styles.instructionItem}>
            {t('instructions.point1')}
          </p>
          <p className={styles.instructionItem}>
            {t('instructions.point2')}
          </p>
          <p className={styles.instructionItem}>
            {t('instructions.point3')}
          </p>
        </div>
        <button
          className={styles.readyButton}
          onClick={onStart}
        >
          {t('instructions.readyButton')}
        </button>
      </div>
    </div>
  );
};

export default InstructionsModal;