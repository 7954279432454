import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  fetchUniversityPublicData, 
  fetchUniversityDetails,
  recordTestStart 
} from '../../utils/api';
import { getSubdomain, getMediaUrl } from '../../utils/helpers';
import LoadingComponent from './LoadingComponent';
import StartQuiz from './StartQuiz';
import UniversityQuiz from './UniversityQuiz';
import ResultPage from './ResultPage';
import styles from '../../styles/UniversityQuizAppStyles/QuizWrapper.module.css';
import { Helmet } from 'react-helmet-async';

const QuizWrapper = () => {
  const [universityData, setUniversityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quizState, setQuizState] = useState('start');
  const [quizResults, setQuizResults] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [secondaryLogoUrl, setSecondaryLogoUrl] = useState('');
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [quizStartTime, setQuizStartTime] = useState(null);
  const [professionProfiles, setProfessionProfiles] = useState([]);
  const { i18n } = useTranslation();

  // Fetch university data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const subdomain = getSubdomain();
        const [publicData, universityInfo] = await Promise.all([
          fetchUniversityPublicData(),
          fetchUniversityDetails(subdomain)
        ]);

        setProfessionProfiles(publicData.data.profession_profiles || []);

        setUniversityData({
          ...publicData.data,
          universityName: universityInfo.data.university_name,
          universityWebsite: universityInfo.data.website,
          questions: publicData.data.questions,
          design: publicData.data.design,
          quiz_settings: publicData.data.quiz_settings
        });
        
        const mediaUrl = getMediaUrl();
        setLogoUrl(`${mediaUrl}${publicData.data.design.primary_logo}`);
        setSecondaryLogoUrl(`${mediaUrl}${publicData.data.design.secondary_logo}`);
        
        const availableLangs = publicData.data.available_languages || ['uz'];
        setAvailableLanguages(availableLangs);
        
        if (availableLangs.length > 0) {
          i18n.changeLanguage(availableLangs[0]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load quiz data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [i18n]);

  const handleStartQuiz = async () => {
    try {
      await recordTestStart();
      const startTime = new Date();
      setQuizStartTime(startTime);
      setQuizState('inProgress');
    } catch (error) {
      console.error('Failed to start quiz:', error);
      setQuizState('inProgress');
    }
  };

  const handleQuizComplete = async (results) => {
    try {
      const endTime = new Date();
      const duration = quizStartTime ? (endTime - quizStartTime) / 1000 : 0;
  
      // Create the complete quiz results object
      setQuizResults(results);
      setQuizState('completed');
    } catch (error) {
      console.error('Failed to complete quiz:', error);
      setError('Failed to process quiz results');
    }
  };

  const handleError = (error) => {
    console.error('Quiz error:', error);
    setError('An error occurred. Please try again later.');
  };

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return (
      <div className={styles.error}>
        {error}
        <button 
          onClick={() => window.location.reload()}
          className={styles.retryButton}
        >
          Try Again
        </button>
      </div>
    );
  }

  // Apply university primary color as background
  const wrapperStyle = {
    backgroundColor: universityData?.design?.primary_color || '#fff'
  };

  return (
    <div className={styles.quizWrapper} style={wrapperStyle}>
      <Helmet>
        <title>{universityData?.universityName}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      
      {loading && <LoadingComponent />}
      
      {error && (
        <div className={styles.error}>
          {error}
          <button 
            onClick={() => window.location.reload()}
            className={styles.retryButton}
          >
            Try Again
          </button>
        </div>
      )}

      {!loading && !error && (
        <>
          {quizState === 'start' && (
            <StartQuiz 
              universityData={universityData}
              logoUrl={logoUrl}
              onStartQuiz={handleStartQuiz}
              availableLanguages={availableLanguages}
              onError={handleError}
            />
          )}

          {quizState === 'inProgress' && (
            <UniversityQuiz 
              universityData={universityData}
              logoUrl={logoUrl}
              onQuizComplete={handleQuizComplete}
              startTime={quizStartTime}
              onError={handleError}
              professionProfiles={professionProfiles}
            />
          )}

          {quizState === 'completed' && quizResults && (
            <ResultPage 
              quizResults={quizResults}
              universityData={universityData}
              secondaryLogoUrl={secondaryLogoUrl}
              professionProfiles={professionProfiles}
            />
          )}
        </>
      )}
    </div>
  );
};

export default QuizWrapper;