import React from 'react';

const PhoneInput = ({ value, onChange, error, className }) => {
  const handleChange = (e) => {
    let input = e.target.value;
    
    // Remove all non-digit characters
    input = input.replace(/\D/g, '');
    
    // Limit to 9 digits
    input = input.slice(0, 9);
    
    // Format with spaces after every 2 digits for display
    let formatted = '';
    for (let i = 0; i < input.length; i++) {
      if (i === 2 || i === 5 || i === 7) {
        formatted += ' ';
      }
      formatted += input[i];
    }
    
    // Pass both formatted display value and complete phone number
    onChange({
      display: formatted,
      value: input ? '+998' + input : '' // Only add prefix if there's input
    });
  };

  const inputContainerStyle = {
    position: 'relative',
    width: '100%',
    maxWidth: '400px'
  };

  return (
    <div style={inputContainerStyle}>
      <input
        type="tel"
        value={value.display}
        onChange={handleChange}
        className={className}
        placeholder="90 123 45 67"
        style={{ paddingLeft: '72px' }}
      />
      <span style={{
        position: 'absolute',
        left: '12px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#666',
        userSelect: 'none',
        pointerEvents: 'none'
      }}>
        +998
      </span>
    </div>
  );
};

export default PhoneInput;