// StartQuiz.jsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/UniversityQuizAppStyles/StartQuiz.module.css';
import InstructionsModal from './InstructionsModal';

const StartQuiz = ({ universityData, logoUrl, onStartQuiz, availableLanguages }) => {
  const { t, i18n } = useTranslation();
  const [showInstructions, setShowInstructions] = useState(false);

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  const handleStartClick = () => {
    setShowInstructions(true);
  };

  const handleInstructionsClose = () => {
    setShowInstructions(false);
  };

  const handleUserReady = () => {
    setShowInstructions(false);
    onStartQuiz();
  };

  const secondaryColor = universityData?.design?.secondary_color || '#000000';
  const primaryColor = universityData?.design?.primary_color || '#FFFFFF';
  const tertiaryColor = universityData?.design?.tertiary_color || '#FF0000';

  return (
    <div className={styles.startQuizContainer} style={{ backgroundColor: primaryColor }}>
      <div className={`${styles.decorativeCircle} ${styles.topCircle}`} style={{ borderColor: secondaryColor }}></div>
      <div className={`${styles.decorativeCircle} ${styles.bottomCircle}`} style={{ borderColor: secondaryColor }}></div>
      
      <div className={styles.mainContent}>
        <div className={styles.headerSection}>
          <a href={universityData.universityWebsite}>
            {logoUrl && (
              <img 
                src={logoUrl} 
                alt={`${universityData.universityName} logo`} 
                className={styles.logo} 
              />
            )}
          </a>
          <select 
            value={i18n.language} 
            onChange={handleLanguageChange} 
            className={styles.languageSelector}
            style={{ 
              color: secondaryColor,
              borderColor: secondaryColor 
            }}
          >
            {availableLanguages.map((lang) => (
              <option key={lang} value={lang}>
                {lang.toUpperCase()}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.contentSection}>
          <div className={styles.titleRow}>
            <h1 className={styles.mainTitle} style={{ color: secondaryColor }}>
              {t('startQuiz.title')}
            </h1>
            <h3 className={styles.tagline} style={{ color: secondaryColor }}>
              {t('startQuiz.tagline.first')}{' '}
              <span style={{ color: tertiaryColor }}>{t('startQuiz.tagline.second')}</span>
              {' '}{t('startQuiz.tagline.third')}
            </h3>
          </div>

          <div className={styles.infoBoxContainer}>
            <div 
              className={styles.infoBox} 
              style={{ backgroundColor: secondaryColor, color: primaryColor }}
            >
              <div className={styles.infoTitle}>{t('startQuiz.infoBox.title1')}</div>
              <div className={styles.infoText}>
                {t('startQuiz.infoBox.text1')}
              </div>
            </div>
            
            <div 
              className={styles.infoBox} 
              style={{ backgroundColor: secondaryColor, color: primaryColor }}
            >
              <div className={styles.infoTitle}>{t('startQuiz.infoBox.title2')}</div>
              <div className={styles.infoText}>
                {t('startQuiz.infoBox.text2')}
              </div>
            </div>
          </div>

          <div className={styles.descriptionRow}>
            <p className={styles.description} style={{ color: secondaryColor }}>
              {t('startQuiz.description')}
            </p>
            <button 
              className={styles.startButton}
              onClick={handleStartClick}
              style={{color: primaryColor, backgroundColor: tertiaryColor}}
            >
              {t('startQuiz.startButton')}
            </button>
          </div>
        </div>
      </div>

      <footer className={styles.footer}>
        <div className={styles.poweredBy}>
          {t('common.poweredBy')}
          <img 
            src="/logo.png" 
            alt="WorkXplorer logo" 
            className={styles.workxplorerIcon} 
          />
        </div>
      </footer>

      {showInstructions && (
        <InstructionsModal 
          onClose={handleInstructionsClose}
          onStart={handleUserReady}
        />
      )}
    </div>
  );
};

export default StartQuiz;