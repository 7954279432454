import axios from 'axios';
import { getApiUrl, getAuthUrl } from './helpers';

// Create API instance
const api = axios.create({
  baseURL: getApiUrl(),
  withCredentials: true,
});

// Add this at the top level for managing token refresh
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Request interceptor for adding auth token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for token refresh
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if error is 401 and request hasn't been retried yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      // Check if the request is for admin dashboard or requires authentication
      const isAdminRequest = originalRequest.url.includes('/api/v1/questions/') ||
                            originalRequest.url.includes('/api/v1/profession-profiles/') ||
                            originalRequest.url.includes('/api/v1/quiz-statistics/') ||
                            originalRequest.url.includes('/api/v1/answer-statistics/');

      // If it's not an admin request, just return the error for public endpoints
      if (!isAdminRequest) {
        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return api(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
          throw new Error('No refresh token available');
        }

        const response = await axios.post(`${getAuthUrl()}/api/v1/token/refresh/`, {
          refresh: refreshToken,
        });

        const newToken = response.data.access;
        localStorage.setItem('access_token', newToken);
        api.defaults.headers['Authorization'] = `Bearer ${newToken}`;
        
        processQueue(null, newToken);
        
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');

        // Only redirect to login if the current page is admin dashboard
        const currentPath = window.location.pathname;
        if (currentPath.includes('/admin-dashboard')) {
          window.location.href = '/login';
        }
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }
    return Promise.reject(error);
  }
);

// Questions API
export const fetchQuestions = () => api.get('/api/v1/questions/');
export const createQuestion = (questionData) => api.post('/api/v1/questions/', questionData);
export const updateQuestion = (questionId, questionData) => api.put(`/api/v1/questions/${questionId}/`, questionData);
export const deleteQuestion = (questionId) => api.delete(`/api/v1/questions/${questionId}/`);

// Profession Profiles API (requires auth)
export const fetchProfessionProfiles = async () => {
  try {
    const response = await api.get('/api/v1/profession-profiles/');
    return {
      data: Array.isArray(response.data) ? response.data : []
    };
  } catch (error) {
    console.error('Error fetching profession profiles:', error);
    if (error.response?.status === 401) {
      throw error; // Let the interceptor handle the 401
    }
    return { data: [] };
  }
};

export const createProfessionProfile = async (profileData) => {
  try {
    const response = await api.post('/api/v1/profession-profiles/', profileData);
    return response;
  } catch (error) {
    console.error('Error creating profession profile:', error);
    throw error;
  }
};

export const updateProfessionProfile = (profileId, profileData) => 
  api.put(`/api/v1/profession-profiles/${profileId}/`, profileData);

export const deleteProfessionProfile = (profileId) => 
  api.delete(`/api/v1/profession-profiles/${profileId}/`);

// University API
export const fetchUniversityPublicData = () => api.get('/api/v1/public-data/');
export const fetchUniversityDetails = (subdomain) => api.get(`${getAuthUrl()}/api/v1/users/university/${subdomain}/`);

// Quiz Statistics (requires auth)
let statsPromise = null;
export const fetchQuizStatistics = async (language = 'uz') => {
  if (statsPromise) {
    return statsPromise;
  }

  try {
    statsPromise = api.get(`/api/v1/quiz-statistics/?language=${language}`);
    const response = await statsPromise;
    return response;
  } catch (error) {
    console.error('Error fetching quiz statistics:', error);
    throw error;
  } finally {
    statsPromise = null;
  }
};

// Answer Statistics (requires auth)
let answerStatsPromise = null;
export const fetchAnswerStatistics = async (language = 'uz') => {
  if (answerStatsPromise) {
    return answerStatsPromise;
  }

  try {
    answerStatsPromise = api.get(`/api/v1/answer-statistics/?language=${language}`);
    const response = await answerStatsPromise;
    return response;
  } catch (error) {
    console.error('Error fetching answer statistics:', error);
    throw error;
  } finally {
    answerStatsPromise = null;
  }
};

// File Upload (requires auth)
export const uploadQuestions = async (formData) => {
  try {
    const response = await api.post('/api/v1/questions/upload/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
    return response;
  } catch (error) {
    console.error('Error uploading questions:', error);
    throw error;
  }
};

// Auth
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${getAuthUrl()}/api/v1/token/`, { email, password });
    const { access, refresh } = response.data;
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);
    api.defaults.headers['Authorization'] = `Bearer ${access}`;
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logout = async () => {
  try {
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      await api.post(`${getAuthUrl()}/api/v1/users/logout/`, {
        refresh_token: refreshToken
      });
    }
  } catch (error) {
    console.error('Logout error:', error);
  } finally {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    delete api.defaults.headers['Authorization'];
    window.location.href = '/login';
  }
};

// Quiz Settings (requires auth)
export const fetchQuizSettings = () => api.get('/api/v1/quiz-settings/');
export const saveQuizSettings = (settings) => api.put('/api/v1/quiz-settings/', settings);

// Quiz Answers (public)
export const recordTestStart = () => api.post('/api/v1/answer/', { is_start: true });

export const submitAnswers = (answerData) => api.post('/api/v1/answer/', {
  ...answerData,
  calculate_profiles: true
});

export const submitQuizResults = async (answerData) => {
  try {
    const response = await api.post('/api/v1/answer/', {
      ...answerData,
      test_completed_at: new Date().toISOString(),
      calculate_profiles: true
    });
    return response;
  } catch (error) {
    console.error('Error submitting quiz results:', error.response?.data || error);
    throw error;
  }
};

// Profession Profiles Upload
export const uploadProfessionProfiles = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await api.post('/api/v1/profession-profiles/upload/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error) {
    console.error('Error uploading profession profiles:', error);
    throw error;
  }
};